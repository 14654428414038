import React from 'react';

// === Components === //
import Page from '@components/layout/Page';
import BookmarkList from '@components/sections/BookmarkList';
import HeroVideo from '@components/sections/HeroVideo';

// === Content === //
import { hcpHomepageData } from '@graphql/HcpHomepage';

// === Styles === //
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { breakpoints, respondFrom } from '@styles';

const BookmarsListWrapper = styled.div`
  transform: translateY(-150px);
  position: relative;
  z-index: 3;

  ${respondFrom(
    breakpoints.md,
    css`
      transform: translateY(-80px);
      margin-bottom: -80px;
    `
  )}
`;

const HCPHomepage = () => {
  const variant = 'red';
  const pageType = 'hcp_page';
  const data = hcpHomepageData();

  return (
    <Page variant={variant} pageType={pageType} metaData={data.metaData} pageUrl="/hcp">
      <>
        <HeroVideo data={data.hero} pageType={pageType} />

        <BookmarsListWrapper>
          <BookmarkList data={data.bookmarks} variant={variant} />
        </BookmarsListWrapper>
      </>
    </Page>
  );
};

export default HCPHomepage;
